/*
 * @Author: heyu
 * @Date: 2024-03-22 09:19:33
 * @LastEditors: heyu
 * @LastEditTime: 2024-03-22 16:44:03
 * @FilePath: \3.22\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'

import i18n from './language'
import echarts from 'echarts'
// //vue全局注入echarts
Vue.prototype.$echarts = echarts;
Vue.use(Antd)
Vue.use(i18n)

Vue.config.productionTip = false
new Vue({
  router,
  store,
  Antd,
  i18n,
  render: h => h(App)
}).$mount('#app')
