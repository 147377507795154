export default {
  home: {
    conn: '连接',
    btn1:'叁与众集',
    btn2:'复制邀请链接',
  },
  menuList: ['众集', 'M314合约规则'],
  burn: {
    gy:'M314合约规则',
    p1: 'M314 : 币安龙头创新314协议生态！100%真正去中心化！公平公开！买卖自由安全！无薄饼底池！无需担心底池被盗！ 杜绝机器人夹子！合约永久自动运行！无需DAPP网站，永不怕关网的DAO治理生态！',
    p2: '币名称：M314',
    p3: '发行总量：2100万枚',
    p4: '买卖税费3%:',
    p5: '1.2%转换为BNB用于燃烧奖励;0.6%转换为BNB用于燃烧地址的权重分红;',
    p6: '1.2%转帐为BNB用于生态推广及市场营销开支。',
    p7: '众集2000份自动上线:每份0.1BNB可获得7600M314，(每次必须0.1BNB默认有效)',
    p8: '众集BNB分配:100 BNB用于燃烧奖励;50BNB注池上线;50BNB用于市值管理。',
    p9: 'M314 交易机制',
    p10: '买入M314：直接转BNB至M314合约地址即可成功买入。',
    p11: '卖出M314：直接转M314至M314合约地址即可成功卖出。',
    p12: '参与燃烧的就转M314至燃烧合约地址',
    p13: '燃烧机制',
    p14: '1.持币地址自我燃烧线性通缩:  每天1%，线性通缩的代币自动到燃烧合约;',
    p15: '2.自我燃烧销毁：代币中嵌入每小时0.25%的销毁，每天总计6%。这将导致代币的长期流通减少。此外代币的自愿销毁将加速销毁过程。销毁功能嵌入代币中，将永远自动销毁。',
    p16: '3.持币者主动燃烧。燃烧奖励：用户使用M314转帐至燃烧合约，燃烧额度可获得相应价值的BNB奖励;获得燃烧额度的分红权重，直接分红BNB至钱包地址，分红权重一直累积。如燃烧合约BNB无额度则燃烧者转帐不成功',
    p17: '邀请奖励30%: 奖励用户上面的邀请人M314代币奖励',
    p18: '1代10%，2代4%，3-10代均2%',
    p19:'邀请绑定推荐关系方式：首次向某地址转账0.001或以上任意M314数量默认为推荐人'
  }
}
