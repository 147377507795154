/*
 * @Author: heyu
 * @Date: 2024-03-22 09:19:33
 * @LastEditors: heyu
 * @LastEditTime: 2024-03-22 16:57:50
 * @FilePath: \3.22\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
// import Top from '@/pages/components/top.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index/home'
  },
  {
    path: '/index',
    name: 'Index',
    component: () => import(/* webpackChunkName: "about" */ '@/pages/components/top.vue'),
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "about" */ '@/pages/home/index.vue'),
      },
      {
        path: 'burnbuild',
        name: 'Burnbuild',
        component: () => import(/* webpackChunkName: "about" */ '@/pages/burnbuild/index.vue'),
      },
    ]
  },
 

]


const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
