export default {
  home: {
    conn: 'Connect',
    btn1:'Mint',
    btn2:'Copy link',
  },
  menuList: ['Home', 'M314 Rules'],
  burn: {
    gy:'M314 Rules',
    p1: 'M314: Binance\'s leading innovative 314 protocol ecosystem! 100% truly decentralized! Fair and open! Buy and sell freely and securely! No pancake pot! No need to worry about your pot being stolen! Put an end to robot clips! The contract runs automatically forever! No DAPP website is required, and the DAO governance ecosystem is never afraid of shutting down the network!',
    p2: 'Coin name: M314',
    p3: 'Total Supply: 21,000,000',
    p4: 'Business tax 3%:',
    p5: '1.2% is converted to BNB for burning rewards; 0.6% is converted to BNB for burning address weight dividends;',
    p6: '1.2% is transferred to BNB for ecological promotion and marketing expenses.',
    p7: '2000 copies of the crowd set are automatically online: 0.1BNB for each copy can get 7600M314, (0.1BNB must be valid by default each time)',
    p8: 'Zhongji BNB distribution: 100 BNB for burning rewards; 50 BNB for the pool online; 50 BNB for market value management.',
    p9: 'M314 Transaction Mechanism!',
    p10: 'Buy M314: Directly transfer BNB to the M314 contract address to successfully buy.',
    p11: 'Sell M314: Directly transfer M314 to the M314 contract address to successfully sell.',
    p12: 'If you participate in burning, please transfer M314 to the burning contract address',
    p13: 'Combustion mechanism',
    p14: '1. The currency holding address self-burns linear deflation: 1% per day, and the linearly deflated tokens automatically go to the burning contract;',
    p15: '2. Self-combustion destruction: 0.25% destruction per hour is embedded in the token, totaling 6% per day. This will lead to a reduction in the long-term circulation of the token. In addition, the voluntary destruction of tokens will accelerate the destruction process. The burn function is embedded in the token and will be automatically destroyed forever.',
    p16: '3. The currency holder takes the initiative to burn. Burning rewards: Users use M314 to transfer to the burning contract, and the burning amount can obtain BNB rewards of corresponding value; obtain the dividend weight of the burning amount, directly distribute BNB dividends to the wallet address, and the dividend weights are accumulated. If there is no BNB limit in the burning contract, the burner\'s transfer will not be successful',
    p17: 'Invitation reward 30%: Reward the inviter above the user with M314 token reward',
    p18: '10% for the 1st generation, 4% for the 2nd generation, 2% for the 3rd to 10th generation',
    p19: 'Invitation binding recommendation relationship method: the first time you transfer 0.001 or above to an address, any amount of M314 will be the recommender by default',
  }
}
